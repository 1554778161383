<template>
  <div>
    <div
      class="supplier-accordeon"
      v-for="(service, index) in serviceRows"
      :key="index"
    >
      <div class="wrapper">
        <div class="name" @click="activeServiceAccordeon(index, service)">
          <a-row type="flex" justify="space-between">
            <a-col class="upper">
              {{ tempContract[`service_${service.id}_name`] }}
            </a-col>

            <a-col>
              <a-button type="link">
                <i class="fas fa-caret-down"></i>
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div v-if="index == activeAccordeon" class="content">
          <a-row v-if="$root.isAdmin()" class="infos" :gutter="10">
            <a-col :span="24">
              <div class="line">
                <span class="label"> Produto: </span>

                <span class="value upper">
                  {{ tempContract[`service_${service.id}_name`] }}
                </span>
              </div>
            </a-col>

            <a-col class="line" :span="8">
              <span class="label"> Valor Venda: </span>

              <span class="value">
                {{
                  formatPricePtBr(
                    tempContract[`service_${service.id}_total_price`]
                  )
                }}
              </span>
            </a-col>

            <a-col class="line" :span="8">
              <span class="label"> Valor Neto: </span>

              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_net_value`
                  ] != undefined
                    ? formatPricePtBr(
                        tempContract[
                          `contract_finances_service_${service.id}_net_value`
                        ]
                      )
                    : "---"
                }}
              </span>
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `contract_finances_service_${service.id}_profit_value`
                ] != undefined
              "
              class="line"
              :span="8"
            >
              <span class="label"> Lucratividade: </span>
              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_profit_value`
                  ] != undefined
                    ? formatPricePtBr(
                        tempContract[
                          `contract_finances_service_${service.id}_profit_value`
                        ]
                      )
                    : "---"
                }}
              </span>
            </a-col>

            <a-col
              v-if="tempContract[`service_${service.id}_locator`]"
              class="line"
              :span="8"
            >
              <span class="label"> Localizador: </span>

              <span class="value">
                {{ tempContract[`service_${service.id}_locator`] }}
              </span>
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `contract_finances_service_${service.id}_reservation_date`
                ] != undefined
              "
              class="line"
              :span="8"
            >
              <span class="label"> DT Reserva: </span>

              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_reservation_date`
                  ]
                }}
              </span>
            </a-col>

            <a-col
              v-if="
                tempContract[
                  `contract_finances_service_${service.id}_reservation_expiration_date`
                ] != undefined
              "
              class="line"
              :span="8"
            >
              <span class="label"> DT Expiração: </span>

              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_reservation_expiration_date`
                  ]
                }}
              </span>
            </a-col>

            <a-col class="line" :span="8">
              <span class="label"> Moeda: </span>

              <span class="value"> BRL </span>
            </a-col>
            <a-col
              v-if="
                tempContract[
                  `contract_finances_service_${service.id}_expiration_date`
                ] != undefined
              "
              class="line"
              :span="8"
            >
              <span class="label"> Vencimento: </span>
              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_expiration_date`
                  ]
                }}
              </span>
            </a-col>
            <a-col
              v-if="
                tempContract[
                  `contract_finances_service_${service.id}_payment_status`
                ] != undefined
              "
              class="line"
              :span="8"
            >
              <span class="label">Pagamento: </span>

              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_payment_status`
                  ]
                }}
              </span>
            </a-col>

            <a-col class="line" :span="8">
              <span class="label">Fornecedor: </span>

              <span class="value">
                {{
                  tempContract[
                    `contract_finances_service_${service.id}_supplier`
                  ] != undefined
                    ? tempContract[
                        `contract_finances_service_${service.id}_supplier`
                      ]
                    : "---"
                }}
              </span>
            </a-col>
          </a-row>

          <a-row class="fields" :gutter="20">
            <a-col :span="6">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Valor total</label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency
                  v-decorator="[
                    `service_${service.id}_total_price`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  @blur="calcProfit(service.id)"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <a-form-item class="travel-input-outer travel-multiselector">
                <label :class="'filled'">
                  Fornecedor
                  <font
                    class="cblack"
                    v-if="
                      tempContract[
                        `contract_finances_service_${service.id}_supplier_infotera`
                      ] &&
                      !tempContract[
                        `contract_finances_service_${service.id}_supplier_id`
                      ]
                    "
                  >
                    {{
                      tempContract[
                        `contract_finances_service_${service.id}_supplier_infotera`
                      ]
                    }}
                  </font>
                </label>
                <a-select
                  class="travel-input"
                  show-search
                  optionFilterProp="txt"
                  placeholder="Selecione"
                  :allow-clear="true"
                  v-decorator="[
                    `contract_finances_service_${service.id}_supplier_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%; height: 30px"
                  @change="onSelectSupplier(service.id)"
                >
                  <a-select-option
                    v-for="(item, index) of productSupplier.list"
                    :key="index"
                    :value="item.id"
                    :txt="item.trading_name"
                  >
                    {{ item.id }} -
                    {{ item.trading_name.toUpperCase() }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col
              class="none"
              v-if="
                form.getFieldValue(
                  `contract_finances_service_${service.id}_supplier_id`
                ) == 'Outro'
              "
              :span="5"
            >
              <a-form-item class="travel-input-outer">
                <a-input
                  class="travel-input"
                  v-decorator="[
                    `contract_finances_service_${service.id}_supplier`,
                  ]"
                  placeholder="Fornecedor"
                />
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label class="filled">Valor Neto</label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency
                  v-decorator="[
                    `contract_finances_service_${service.id}_net_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  @blur="calcProfit(service.id)"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label class="filled">Desconto</label>
                <a-input
                  class="travel-input"
                  placeholder="Insira"
                  v-currency
                  v-decorator="[
                    `contract_finances_service_${service.id}_discount_value`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                  @blur="discountValue(service.id)"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Dia do pagamento</label>
                <a-date-picker
                  class="travel-input"
                  placeholder="Insira"
                  format="DD/MM/YYYY"
                  :showToday="false"
                  :allowClear="true"
                  v-mask="'##/##/####'"
                  v-decorator="[
                    `contract_finances_service_${service.id}_payday`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-date-picker>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <h4 class="subtitile">
                <a-row type="flex" justify="space-between">
                  <a-col>Taxas Extras</a-col>
                  <a-col>
                    <a-button
                      class="add-button color"
                      size="small"
                      @click="addTaxRow(service.id)"
                    >
                      Adicionar
                    </a-button></a-col
                  >
                </a-row>
              </h4>
              <a-row
                v-for="(tax, index) in convertStrArr(
                  tempContract[
                    `contract_finances_service_${service.id}_taxes_rows`
                  ]
                )"
                :gutter="20"
                :key="index"
              >
                <a-col :span="5">
                  <a-form-item class="travel-input-outer">
                    <label class="filled">Taxas (R$)</label>
                    <a-input
                      class="travel-input"
                      placeholder="Insira"
                      v-currency
                      v-decorator="[
                        `contract_finances_service_${service.id}_tax_${tax.id}_value`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                    >
                      <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-form-item class="travel-input-outer travel-multiselector">
                    <label :class="'filled'"> Tipo </label>

                    <a-select
                      class="travel-input"
                      show-search
                      :allow-clear="true"
                      optionFilterProp="txt"
                      placeholder="Selecione"
                      v-decorator="[
                        `contract_finances_service_${service.id}_tax_${tax.id}_type`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="(item, index) of taxesType"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="7">
                  <a-form-item>
                    <a-checkbox
                      :checked="
                        tempContract[
                          `contract_finances_service_${service.id}_tax_${tax.id}_add_in_total`
                        ] == 1
                          ? true
                          : false
                      "
                      v-decorator="[
                        `contract_finances_service_${service.id}_tax_${tax.id}_add_in_total`,
                      ]"
                      >Somar no valor total</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :span="3">
                  <a-button
                    v-if="
                      tax.id ==
                      convertStrArr(
                        tempContract[
                          `contract_finances_service_${service.id}_taxes_rows`
                        ]
                      ).length
                    "
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    @click="removeTaxRow(service.id, tax.id)"
                  >
                  </a-button>
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="24">
              <h4 class="subtitile">
                <a-row type="flex" justify="space-between">
                  <a-col>Incentivos</a-col>
                  <a-col>
                    <a-button
                      class="add-button color"
                      size="small"
                      @click="addIncentiveRow(service.id)"
                    >
                      Adicionar
                    </a-button></a-col
                  >
                </a-row>
              </h4>

              <a-row
                v-for="(incentive, index) in convertStrArr(
                  tempContract[
                    `contract_finances_service_${service.id}_incentives_rows`
                  ]
                )"
                :gutter="20"
                :key="index"
              >
                <a-col :span="5">
                  <a-form-item class="travel-input-outer travel-multiselector">
                    <label :class="'filled'"> Tipo </label>

                    <a-select
                      class="travel-input"
                      show-search
                      :allow-clear="true"
                      optionFilterProp="txt"
                      placeholder="% ou R$"
                      v-decorator="[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_type`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    >
                      <a-select-option value="%"> % </a-select-option>
                      <a-select-option value="R$"> R$ </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :span="8">
                  <a-row :gutter="8">
                    <a-col :span="24">
                      <a-form-item class="travel-input-outer">
                        <label class="filled">Valor incentivo</label>
                        <a-input
                          class="travel-input"
                          placeholder="Insira"
                          v-currency="{
                            currency: {
                              prefix: '',
                              suffix: '',
                            },
                            distractionFree: false,
                            valueAsInteger: false,
                            precision: 2,
                            autoDecimalMode: false,
                            allowNegative: false,
                          }"
                          v-decorator="[
                            `contract_finances_service_${service.id}_incentive_${incentive.id}_value`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'Obrigatório',
                                },
                              ],
                            },
                          ]"
                          :disabled="
                            form.getFieldValue(
                              `contract_finances_service_${service.id}_incentive_${incentive.id}_type`
                            )
                              ? false
                              : true
                          "
                          @blur="
                            calcIncentivesPercentage(service.id, incentive.id)
                          "
                        >
                          <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>

                <a-col :span="7">
                  <a-form-item>
                    <a-checkbox
                      :checked="
                        tempContract[
                          `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`
                        ] == 1
                          ? true
                          : false
                      "
                      v-decorator="[
                        `contract_finances_service_${service.id}_incentive_${incentive.id}_add_in_total`,
                      ]"
                      >Somar no valor total</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :span="3">
                  <a-button
                    v-if="
                      incentive.id ==
                      convertStrArr(
                        tempContract[
                          `contract_finances_service_${service.id}_incentives_rows`
                        ]
                      ).length
                    "
                    size="small"
                    type="danger"
                    shape="circle"
                    icon="delete"
                    @click="removeIncentiveRow(service.id, incentive.id)"
                  >
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import { parse } from "vue-currency-input";

export default {
  name: "ContractFinancesServiceTab",
  mixins: [formatThings, productSupplierMixins],
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
  },
  data() {
    return {
      activeAccordeon: -1,
      contractedServices: [],
      serviceRows: [],
      taxesType: [],
    };
  },
  mounted() {
    this.contractedServices =
      this.tempContract.contracted_services != undefined
        ? JSON.parse(this.tempContract.contracted_services)
        : [];

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=3&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.taxesType = data.data;
      });

    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    this.serviceRows = this.tempContract.service_rows
      ? JSON.parse(this.tempContract.service_rows)
      : [];

    this.serviceRows.forEach((service) => {
      this.calcProfit(service.id);

      if (
        this.tempContract[
          `contract_finances_service_${service.id}_taxes_rows`
        ] == undefined
      ) {
        this.tempContract[
          `contract_finances_service_${service.id}_taxes_rows`
        ] = JSON.stringify([]);
      }

      if (
        this.tempContract[
          `contract_finances_service_${service.id}_incentives_rows`
        ] == undefined
      ) {
        this.tempContract[
          `contract_finances_service_${service.id}_incentives_rows`
        ] = JSON.stringify([]);
      }
    });
  },

  methods: {
    discountValue(id) {
      let discount = 0,
        totalValue = 0;

      if (this.tempContract[`contract_finances_service_${id}_discount_value`]) {
        discount =
          this.tempContract[`contract_finances_service_${id}_discount_value`];
      }

      totalValue = this.tempContract[`service_${id}_total_price`];

      if (totalValue) {
        totalValue = totalValue.replace(",", ".");
        totalValue = parseFloat(totalValue);
      }

      if (discount) {
        discount = discount.replace(",", ".");
        discount = parseFloat(discount);
      }

      let newTotalPrice = totalValue - discount;

      this.form.setFieldsValue({
        [`service_${id}_total_price`]: newTotalPrice
          .toString()
          .replace(".", ","),
      });

      setTimeout(() => {
        this.calcProfit(id);
      }, 100);
    },
    calcIncentivesPercentage(id, incentiveId) {
      let percentage = 0,
        totalValue = 0;

      percentage =
        this.tempContract[
          `contract_finances_service_${id}_incentive_${incentiveId}_value`
        ];

      if (percentage) {
        percentage = parseFloat(percentage.replace(",", "."));
        percentage = percentage / 100;
      } else {
        percentage = 0;
      }

      totalValue = this.tempContract[`service_${id}_total_price`];

      if (totalValue) {
        totalValue = parseFloat(totalValue.replace(",", "."));
      } else {
        totalValue = 0;
      }

      if (totalValue && percentage) {
        this.tempContract[
          `contract_finances_service_${id}_incentive_${incentiveId}_percentage_value`
        ] = (percentage * totalValue).toFixed(2);
      }
    },
    calcProfit(serviceId) {
      let value = 0,
        netValue = 0,
        profitvalue = 0;

      if (this.tempContract[`service_${serviceId}_total_price`] != undefined) {
        value = parse(this.tempContract[`service_${serviceId}_total_price`]);
      }

      if (
        this.tempContract[`contract_finances_service_${serviceId}_net_value`] !=
        undefined
      ) {
        netValue = parse(
          this.tempContract[`contract_finances_service_${serviceId}_net_value`]
        );
      }

      profitvalue = value - netValue;

      this.tempContract[`contract_finances_service_${serviceId}_profit_value`] =
        profitvalue;
    },
    activeServiceAccordeon(current, service) {
      if (current == this.activeAccordeon) {
        this.activeAccordeon = -1;
      } else {
        this.activeAccordeon = current;
      }

      setTimeout(() => {
        this.setServiceFields(service);
      }, 1);
    },
    setServiceFields(service) {
      this.form.setFieldsValue({
        [`contract_finances_service_${service.id}_payday`]:
          this.tempContract[`contract_finances_service_${service.id}_payday`],
        [`service_${service.id}_total_price`]:
          this.tempContract[`service_${service.id}_total_price`],
        [`contract_finances_service_${service.id}_supplier_id`]:
          this.tempContract[
            `contract_finances_service_${service.id}_supplier_id`
          ],
        [`contract_finances_service_${service.id}_discount_value`]:
          this.tempContract[
            `contract_finances_service_${service.id}_discount_value`
          ],
        [`contract_finances_service_${service.id}_net_value`]:
          this.tempContract[
            `contract_finances_service_${service.id}_net_value`
          ],
        [`contract_finances_service_${service.id}_markup_value`]:
          this.tempContract[
            `contract_finances_service_${service.id}_markup_value`
          ],
        [`contract_finances_service_${service.id}_taxes_value`]:
          this.tempContract[
            `contract_finances_service_${service.id}_taxes_value`
          ],
        [`contract_finances_service_${service.id}_taxes_type`]:
          this.tempContract[
            `contract_finances_service_${service.id}_taxes_type`
          ],
        [`contract_finances_service_${service.id}_incentives_type`]:
          this.tempContract[
            `contract_finances_service_${service.id}_incentives_type`
          ],

        [`contract_finances_service_${service.id}_incentives_value`]:
          this.tempContract[
            `contract_finances_service_${service.id}_incentives_value`
          ],
      });

      setTimeout(() => {
        JSON.parse(
          this.tempContract[
            `contract_finances_service_${service.id}_taxes_rows`
          ]
        ).forEach((tax) => {
          this.form.setFieldsValue({
            [`contract_finances_service_${service.id}_tax_${tax.id}_value`]:
              this.tempContract[
                `contract_finances_service_${service.id}_tax_${tax.id}_value`
              ],
            [`contract_finances_service_${service.id}_tax_${tax.id}_type`]:
              this.tempContract[
                `contract_finances_service_${service.id}_tax_${tax.id}_type`
              ],
          });
        });
        setTimeout(() => {
          JSON.parse(
            this.tempContract[
              `contract_finances_service_${service.id}_incentives_rows`
            ]
          ).forEach((tax) => {
            this.form.setFieldsValue({
              [`contract_finances_service_${service.id}_incentive_${tax.id}_value`]:
                this.tempContract[
                  `contract_finances_service_${service.id}_incentive_${tax.id}_value`
                ],
              [`contract_finances_service_${service.id}_incentive_${tax.id}_type`]:
                this.tempContract[
                  `contract_finances_service_${service.id}_incentive_${tax.id}_type`
                ],
            });
          });
        }, 1);
      }, 1);
    },
    onSelectSupplier(id) {
      setTimeout(() => {
        let supplierId =
            this.tempContract[`contract_finances_service_${id}_supplier_id`],
          theSupplier = this.productSupplier.list.filter((supplier) => {
            return supplier.id == supplierId;
          });

        this.tempContract[`contract_finances_service_${id}_supplier`] =
          theSupplier[0].trading_name;
      }, 10);
    },
    convertStrArr(arr) {
      return arr ? JSON.parse(arr) : [];
    },
    addIncentiveRow(serviceId) {
      let arr = JSON.parse(
        this.tempContract[
          `contract_finances_service_${serviceId}_incentives_rows`
        ]
      );

      setTimeout(() => {
        arr.push({
          id: arr.length + 1,
        });

        this.tempContract[
          `contract_finances_service_${serviceId}_incentives_rows`
        ] = JSON.stringify(arr);
      }, 1);
    },
    removeIncentiveRow(serviceId, id) {
      let arr = JSON.parse(
        this.tempContract[
          `contract_finances_service_${serviceId}_incentives_rows`
        ]
      );

      for (var i in arr) {
        if (arr[i].id == id) {
          arr.splice(i, 1);
          break;
        }
      }

      this.tempContract[
        `contract_finances_service_${serviceId}_incentives_rows`
      ] = JSON.stringify(arr);
    },
    addTaxRow(serviceId) {
      let arr = JSON.parse(
        this.tempContract[`contract_finances_service_${serviceId}_taxes_rows`]
      );

      setTimeout(() => {
        arr.push({
          id: arr.length + 1,
        });

        this.tempContract[`contract_finances_service_${serviceId}_taxes_rows`] =
          JSON.stringify(arr);
      }, 100);
    },
    removeTaxRow(serviceId, id) {
      let arr = JSON.parse(
        this.tempContract[`contract_finances_service_${serviceId}_taxes_rows`]
      );

      for (var i in arr) {
        if (arr[i].id == id) {
          arr.splice(i, 1);
          break;
        }
      }

      this.tempContract[`contract_finances_service_${serviceId}_taxes_rows`] =
        JSON.stringify(arr);
    },
  },
};
</script>

<style lang="sass" scoped>
.add-button
  font-size: 11px
  font-weight: 600
  color: #bbb
  border-color: #ddd
  padding: 2px 5px !important
  line-height: 4px
  height: 22px
  top: -3px
  position: relative
.add-button.color
  border-color: #be4178
  color: #be4178

.supplier-accordeon
  .name
    background: #6c7a89
    border-bottom: 1px solid #fff
    text-align: center
    color: #fff
    padding: 7px 10px 7px 25px
    font-size: 13px
    line-height: 28px
    cursor: pointer
    button
      color: #fff
      height: auto
  .fields
    padding: 30px 25px
    .subtitile
      text-transform: uppercase
      font-size: 11px
      padding: 5px 0
      border-bottom: 1px solid #ddd
      margin-bottom: 20px
  .content
    .infos
      font-size: 12px
      padding: 10px 25px
      border-bottom: 1px solid #ddd
      .line
        padding: 3px 0
        .label
          color: #808183
        .value
          font-weight: 500
          color: #59595b
</style>
