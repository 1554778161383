<template>
  <span v-if="arr != undefined">
    <aDropdown
      v-if="arr.length > 0"
      class="taxes-drop"
      placement="bottomRight"
      overlayClassName="taxes-drop"
    >
      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
        <img
          src="@/assets/images/dashboard/contracts/info.png"
          alt="img"
          width="16"
        />
      </a>
      <a-menu slot="overlay">
        <a-menu-item v-for="(item, index) in sortNames(arr)" :key="index">
          <a-row type="flex" class="f11" :gutter="10">
            <a-col class="number" style="width: 25px"
              ><div v-html="index + 1"></div
            ></a-col>
            <a-col class="name dotted-phrase" style="width: 250px"
              ><div v-html="item.name"></div
            ></a-col>
            <a-col class="value" style="font-weight: 600"
              ><div v-html="item.value"></div
            ></a-col>
          </a-row>
        </a-menu-item>
      </a-menu>
    </aDropdown>
  </span>
</template>

<script>
export default {
  name: "ContractValuesInfoList",
  props: {
    arr: Array,
  },
  methods: {
    sortNames(arr) {
      let sortArr = [];
      sortArr = arr;
      return sortArr;
    },
  },
};
</script>

<style lang="sass" scoped>
.taxes-drop
  li
    padding: 1px 8px 1px 1px
    text-transform: uppercase
  .name
    font-size: 10px
    font-weight: 600
    .f10
        font-size: 8px !important
  .number
    text-align: center
    color: #bbb
    font-size: 8px
    background: #fafafa

.ant-dropdown-menu
  overflow: auto
  max-height: 300px
.ant-dropdown-menu-item
  border-bottom: 1px solid #eee
</style>
