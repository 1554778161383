import { format } from "date-fns";

export default {
  data() {
    return {
      contractFromDatabase: {},
      theTitle: "",
      milesCardOrder: undefined,
      milesCardOrdersIsConcluded: false,
    };
  },
  beforeMount() {
    if (this.$route.name == "new") {
      if (
        this.$store.state.newContract["company"] != undefined &&
        this.$store.state.newContract["company_branch"] != undefined
      ) {
        document.title = `Novo Contrato - ${this.$store.state.newContract.company}`;

        let data = this.$store.state.newContract;

        if (data.meta["payment_methods"] != undefined) {
          this.paymentMethods = data.meta.payment_methods;
        }

        // CONTRATO
        this.contract = {
          ...data,
        };

        // CONTRATO TEMPORARIO
        this.tempContract = {
          ...data,
        };

        this.tempContract.the_customer = {};

        // CONTRATO DO BANCO DE DADOS PARA COMPARAÇÃO

        this.openCustomerModal = true;

        let allFieldsSize = Object.values(data.meta).length,
          values = Object.values(data.meta),
          keys = Object.keys(data.meta);

        for (let i = 0; i < allFieldsSize; i++) {
          this.tempContract[keys[i]] = values[i];
        }

        delete this.tempContract.meta;

        //this.tempContract.contracted_services = "[]";

        setTimeout(() => {
          if (data.meta.client_name != undefined) {
            this.createCustomer = true;
            this.customers.details = {
              first_name: data.meta.client_name.split(" ")[0],
              last_name: data.meta.client_name.split(" ").shift(),
              email: data.meta.client_email,
              birthday: data.meta.client_birthday,
              phone: data.meta.client_phone,
              mobile_phone: data.meta.client_mobile_phone,
              cep: data.meta.client_CEP,
              location_complement: data.meta.client_location_complement,
              location_street: data.meta.client_location_street,
              location_neighborhood: data.meta.client_location_neighborhood,
              location_number: data.meta.client_location_number,
              location_city: data.meta.client_location_city,
              location_state: data.meta.client_location_state,
              cnpj: data.meta.client_CNPJ,
              rg: data.meta.client_RG,
              doc_emission: data.meta.client_doc_emission,
              doc_emission_state: data.meta.client_doc_emission_state,
              cpf: data.meta.client_CPF,
            };
          }
        }, 500);
      } else {
        this.$tabs.close(`/contracts/new2`, `/contracts/list`);
      }
    } else {
      this.$http
        .post(`/contract-v2/details?id=${this.$route.params.id}`, {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          // CONTRATO
          this.contract = {
            ...data,
          };

          // CONTRATO TEMPORARIO
          this.tempContract = {
            ...data,
            the_customer: { ...data.customer, ...data.customer.meta },
          };

          this.contractFromDatabase = {
            ...data,
            the_customer: { ...data.customer, ...data.customer.meta },
          };

          let allFieldsSize = Object.values(data.meta).length,
            values = Object.values(data.meta),
            keys = Object.keys(data.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.tempContract[keys[i]] = values[i];
            this.contractFromDatabase[keys[i]] = values[i];
          }

          this.tempContract.user = this.tempContract.user.id;
          this.tempContract.customer_id = this.tempContract.customer.id;

          this.contractFromDatabase.user = this.contractFromDatabase.user.id;
          this.contractFromDatabase.customer_id =
            this.contractFromDatabase.customer.id;

          this.activeTab =
            this.tempContract.contracted_services != undefined
              ? JSON.parse(this.tempContract.contracted_services)[0]
              : "";

          delete this.tempContract.meta;
          delete this.tempContract.customer;

          delete this.tempContract.the_customer.meta;
          delete this.contractFromDatabase.the_customer.meta;

          delete this.contractFromDatabase.meta;
          delete this.contractFromDatabase.customer;

          let name = `CONTRATANTE`;

          if (data.customer == false) {
            if (data.meta.client_name != undefined) {
              name = `${data.meta.client_name.toUpperCase()}`;
            }
          } else {
            this.customers.details = data.customer;

            if (data.customer.person_type == "Pessoa Física") {
              name = `${data.customer.first_name.toUpperCase()} ${data.customer.last_name.toUpperCase()}`;
            } else {
              name = `${data.customer.trading_name.toUpperCase()} `;
            }
          }

          this.theTitle = `${this.contract.id} - ${name} - HAYA`;

          document.title = this.theTitle;

          this.contractedServices = data.meta.contracted_services
            ? JSON.parse(data.meta.contracted_services)
            : [];

          if (data.customer.id) {
            this.customers.details = data.customer;
          }

          setTimeout(() => {
            this.contractPricingData(this.tempContract);
            this.validateFields();
            this.allowGenerateContract();

            setTimeout(() => {
              this.allowUserToEdit =
                this.tempContract.allow_user_edit == "1" ? "true" : false;
            }, 300);

            if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
              if (data.user.id != this.$store.state.userData.id) {
                this.$tabs.close(
                  `/contracts/edit/${this.contract.id}`,
                  `/contracts/list`
                );
              }
            }

            if (!this.$root.isAdmin() && !this.$root.isAnalyst()) {
              if (data.meta.contract_done) {
                if (data.meta.allow_user_edit_expiration_time != undefined) {
                  if (
                    new Date().getTime() >
                    parseInt(data.meta.allow_user_edit_expiration_time)
                  ) {
                    this.$tabs.close(
                      `/contracts/edit/${data.id}`,
                      `/contracts/list`
                    );
                  }
                } else {
                  if (data.meta.allow_user_edit != "1") {
                    this.$tabs.close(
                      `/contracts/edit/${data.id}`,
                      `/contracts/list`
                    );
                  }
                }
              }
            }
          }, 500);

          this.checkIfAllMilesOrdersIsConcluded();
          //   const formDB = this.tempContract;
          // this.contractFromDatabase = formDB; // CONTRATO DO BANCO PARA COMPARAÇÃO COM O TEMPCONTRACT
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          setTimeout(() => {
            this.$tabs.close(
              `/contracts/edit/${this.contract.id}`,
              `/contracts/list`
            );
          }, 5);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    getSaleByContractId() {
      this.$http
        .post(
          `/sale/list?page=1&per_page=1&contract-id=${this.tempContract.id}&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.tempContract.sale_id = data.data[0].id;

          this.$http.post("/contract-v2/update-field", {
            id: this.tempContract.id,
            field: "sale_id",
            value: data.data[0].id,
          });
        });
    },
    compareContractObjects(obj1, obj2) {
      const diff = {};
      for (const key in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, key)) {
          if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            diff[key] = obj1[key];
          } else if (obj1[key] !== obj2[key]) {
            diff[key] = obj1[key];
          }
        }
      }
      for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
            diff[key] = obj2[key];
          } else if (obj2[key] !== obj1[key]) {
            diff[key] = obj2[key];
          }
        }
      }
      return diff;
    },
    checkIfAllMilesOrdersIsConcluded() {
      let flag = false;
      if (this.tempContract.flight_sections_rows != undefined) {
        JSON.parse(this.tempContract.flight_sections_rows).forEach((flight) => {
          if (this.tempContract[`flight_${flight.id}_type`] == "Milhas") {
            flight.airlines.forEach((airline) => {
              if (
                this.tempContract[
                  `flight_${flight.id}_airline_${airline.id}_miles_order_id`
                ] != undefined
              ) {
                flag = true;
                this.getMilesOrders();
              }
            });
          }
        });
      }
      return flag;
    },
    getMilesOrders() {
      this.$http
        .get(
          `/miles-card-order/list?page=1&per_page=8&contract_id=${this.tempContract.id}&status=Concluído`
        )
        .then(({ data }) => {
          data;
          this.milesCardOrdersIsConcluded = true;
        })
        .catch(({ response }) => {
          response;
          this.milesCardOrdersIsConcluded = false;
        });
    },
    verifyVoucherPayments() {
      let flag = false,
        payments =
          this.tempContract["voucher_payments_method"] != undefined
            ? JSON.parse(this.tempContract["voucher_payments_method"])
            : [];

      if (payments.length > 0) {
        if (
          payments.includes("credit-card") ||
          payments.includes("voucher-payment")
        ) {
          if (
            this.tempContract["voucher_payments_voucher_1_transaction_id"] ||
            this.tempContract["voucher_payments_credit_card_transaction_id"]
          ) {
            flag = true;
          }
        }
      }

      return flag;
    },
    contractedServicesChange() {
      setTimeout(() => {
        this.contractedServices = this.form.getFieldValue(
          "contracted_services"
        );

        this.activeTab = this.form.getFieldValue("contracted_services")[0];
        this.tempContract["active_tab"] = this.activeTab;
        setTimeout(() => {
          this.allContractUpdates();
        }, 100);
      }, 100);
    },
    sendContractEmail(optId) {
      let fromEmail = undefined,
        ccEmail = {
          cc: [this.contract.user.email],
        },
        contractData = this.tempContract.contract_email
          ? JSON.parse(this.tempContract.contract_email)
          : {};

      if (this.contract.company_branch.from_email) {
        fromEmail = {
          from: {
            name: this.contract.company_branch.name,
            email: this.contract.company_branch.from_email,
          },
        };
      } else {
        fromEmail = {
          from: {
            name: this.contract.company_branch.name,
            email: "no-reply@haya.social",
          },
        };
      }

      if (contractData.contracted_services == undefined) {
        contractData.contracted_services = JSON.parse(
          this.tempContract.contracted_services
        );
      }

      if (this.sendEmailToCustomer) {
        this.$http
          .post("/email/contract/send-contract-data", {
            contract: contractData,
            to: {
              name: this.contract.customer.first_name,
              email: this.contract.customer.email,
            },
            ...fromEmail,
            ...ccEmail,
            subject: "Detalhes da sua viagem",
          })
          .then(({ data }) => {
            this.$message.success(data.message);

            let updateContract = {
              id: this.tempContract.id,
              customer_id: this.tempContract.customer_id,
              company_id: this.tempContract.company_id,
              company_branch_id: this.tempContract.company_branch_id,
              opportunity_id: optId ? optId : this.tempContract.opportunity_id,
              id_infotera: this.tempContract.id_infotera,
              user: this.tempContract.user,
              company: this.tempContract.company,
              value: this.tempContract.value,
              contracted_services: this.tempContract.contracted_services,
              release_date: this.tempContract.release_date,
              package_type: this.tempContract.package_type,
              value_to_pay: this.tempContract.value_to_pay,
              taxes_value: this.tempContract.taxes_value,
              profit_value: this.tempContract.profit_value,
              profit_percentage: this.tempContract.profit_percentage,
              payment_methods: this.tempContract.payment_methods,
              marketing_types: this.tempContract.marketing_types,
              first_checkin: this.tempContract.first_checkin,
              last_checkout: this.tempContract.last_checkout,
              status: this.tempContract.status,
              contract_email_is_sent: true,
              modified_by: {
                name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                id: this.$store.state.userData.id,
              },
            };

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.id,
              module: "contract",
              action: "send-contract-email",
              description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> enviou o e-mail do contrato <font class="id">ID ${this.tempContract.id}</font>.`,
            });

            this.emailIsSent = true;
            this.openDocumentsModal = false;
            this.$router.push(`/contracts/view/${this.contract.id}`);

            this.$http.post("/contract-v2/update", updateContract).then(() => {
              this.systemLoadings.generateContractLoading = false;
            });
          })
          .catch(() => {
            this.$message.error("O e-mail do cliente não pôde ser enviado.");
            this.emailIsSent = true;
            this.openDocumentsModal = false;
            this.$router.push(`/contracts/view/${this.contract.id}`);
          });
      }
    },
    protectData(data) {
      let asterisk = "";
      if (data) {
        for (let i = 0; i < data.length; i++) {
          asterisk += "x";
        }
      } else {
        asterisk = "";
      }

      return `${asterisk}`;
    },
    protectCreditCardData() {
      if (this.tempContract.credit_card_rows != undefined) {
        JSON.parse(this.tempContract.credit_card_rows).forEach((card) => {
          if (
            this.tempContract[
              `payment_methods_credit_card_client_name_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_name_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_name_${card.id}`
              ]
            );
          }

          if (
            this.tempContract[
              `payment_methods_credit_card_client_number_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_number_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_number_${card.id}`
              ]
            );
          }

          if (
            this.tempContract[
              `payment_methods_credit_card_client_month_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_month_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_month_${card.id}`
              ]
            );
          }

          if (
            this.tempContract[
              `payment_methods_credit_card_client_year_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_year_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_year_${card.id}`
              ]
            );
          }

          if (
            this.tempContract[
              `payment_methods_credit_card_client_cvv_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_cvv_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_cvv_${card.id}`
              ]
            );
          }

          if (
            this.tempContract[
              `payment_methods_credit_card_client_flag_${card.id}`
            ] != undefined
          ) {
            this.tempContract[
              `payment_methods_credit_card_masked_flag_${card.id}`
            ] = this.protectData(
              this.tempContract[
                `payment_methods_credit_card_client_flag_${card.id}`
              ]
            );
          }
        });
      }
    },
    updateContract() {
      this.form.validateFields(["contract_documents"], (err) => {
        if (!err) {
          this.systemLoadings.updateContractLoading = true;

          this.protectCreditCardData();

          if (this.milesTaxesPayments().show) {
            this.tempContract.flight_taxes_total =
              this.milesTaxesPayments().totalTaxes;
          }

          this.tempContract.user_id = this.$store.state.userData.id;
          this.tempContract.user_name =
            this.$store.state.userData.first_name +
            " " +
            this.$store.state.userData.last_name;
          this.tempContract.user_role = this.$store.state.userData.role;

          this.tempContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          document.title = `Salvando contrato...`;

          // Este método envia apenas os campos que foram alterados
          let contractDataToSave = this.compareContractObjects(
            this.contractFromDatabase,
            this.tempContract
          );

          contractDataToSave.id = this.tempContract.id;
          contractDataToSave.customer_id = this.tempContract.customer_id;
          contractDataToSave.sale_id = this.tempContract.sale_id;
          contractDataToSave.opportunity_id = this.tempContract.opportunity_id;
          contractDataToSave.company_id = this.tempContract.company_id;
          contractDataToSave.company_branch_id =
            this.tempContract.company_branch_id;
          contractDataToSave.id_infotera = this.tempContract.id_infotera;
          contractDataToSave.package_type = this.tempContract.package_type;
          contractDataToSave.user = this.tempContract.user;
          contractDataToSave.company = this.tempContract.company;
          contractDataToSave.value = this.tempContract.value;
          contractDataToSave.value_to_pay = this.tempContract.value_to_pay;
          contractDataToSave.taxes_value = this.tempContract.taxes_value;
          contractDataToSave.profit_value = this.tempContract.profit_value;
          contractDataToSave.profit_percentage =
            this.tempContract.profit_percentage;
          contractDataToSave.contracted_services =
            this.tempContract.contracted_services;
          contractDataToSave.payment_methods =
            this.tempContract.payment_methods;
          contractDataToSave.marketing_types =
            this.tempContract.marketing_types;
          contractDataToSave.first_checkin = this.tempContract.first_checkin;
          contractDataToSave.last_checkout = this.tempContract.last_checkout;
          contractDataToSave.release_date = this.tempContract.release_date;
          contractDataToSave.status = this.tempContract.status;

          this.$http
            .post("/contract-v2/update", contractDataToSave)
            .then(({ data }) => {
              this.$message.success(data.message);
              document.title = this.theTitle;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.systemLoadings.updateContractLoading = false;
            });
        }
      });
    },
    createContract() {
      this.tempContract.status = "edit";
      this.tempContract.release_date = "";
      this.protectCreditCardData();

      this.customerForm.validateFields(
        (customerFormErrors, customerFormVals) => {
          if (!customerFormErrors) {
            this.tempContract.the_customer = {
              ...this.tempContract.the_customer,
              ...customerFormVals,
            };
          }
        }
      );

      this.$http
        .post("/contract-v2/create", this.tempContract)
        .then(({ data }) => {
          this.systemLoadings.updateContractLoading = true;
          this.$message.success(data.message);
          this.$tabs.close(`/contracts/new`, `edit/${data.id}`);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    createOpportunity() {
      let optData = {
        customer_id: this.tempContract.customer_id,
        contract_id: this.tempContract.id,
        customer_name: `${this.tempContract.the_customer.first_name} ${this.tempContract.the_customer.last_name}`,
        funnel_id: "1",
        mobile_phone: this.tempContract.the_customer.mobile_phone,
        origin: this.tempContract.customer_origin,
        title: `Oportunidade gerada do contrato ID ${this.tempContract.id}`,
        step: this.tempContract.opportunity_step,
        email: this.tempContract.the_customer.email,
        unique_id: this.tempContract.the_customer.email,
        situation: "Nenhuma",
        status: this.tempContract.opportunity_status,
        user_id: this.tempContract.user,
        value: this.tempContract.contract_total_value,
        release_date: format(new Date(), "yyyy/MM/dd"),
        modified_by: {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        },
      };
      this.$http.post("/opportunity/create", optData).then(({ data }) => {
        let optId = data.id;

        this.onGenerateContractCreateSale(optId);

        this.$http.post("/log/create", {
          user_id: this.$store.state.userData.id,
          module_id: data.id,
          module: "opportunity",
          action: "create-opportunity",
          description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} criou uma nova oportunidade ID ${data.id} para etapa <b> ${this.opportunityStep.selected.name}</b>.`,
        });
      });
    },
    onGenerateContractCreateSale(optId) {
      this.$http
        .post("/sale/create", {
          user_id: this.tempContract.user,
          company_id: this.tempContract.company_id,
          company_branch_id: this.tempContract.company_branch_id,
          contract_id: this.tempContract.id,
          opportunity_id: optId ? optId : this.tempContract.opportunity_id,
          customer_id: this.tempContract.customer_id,
          value: this.tempContract.contract_total_value,
          taxes_value: this.tempContract.contract_taxes_value,
          profit_value: this.tempContract.contract_profit_value,
          value_to_pay: this.tempContract.contract_net_value,
          status: "Revisão",
          passengers: "[]",
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(({ data }) => {
          this.$http.post("/contract-v2/update-field", {
            id: this.tempContract.id,
            field: "sale_id",
            value: data.id,
          });

          this.$http.post("/contract-v2/update-field", {
            id: this.tempContract.id,
            field: "opportunity_id",
            value: optId,
          });

          if (this.sendEmailToCustomer) {
            this.sendContractEmail(optId);
          } else {
            this.systemLoadings.generateContractLoading = false;
            this.openDocumentsModal = false;
            this.$router.push(`/contracts/view/${this.contract.id}`);
          }
        });
    },
    updateOpportunityValueOnFinishContract() {
      if (this.tempContract.opportunity_id)
        this.$http
          .post("/opportunity/update-field", {
            id: this.tempContract.opportunity_id,
            field: "value",
            value: this.tempContract.contract_total_value,
          })
          .then(() => {
            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.opportunity_id,
              module: "opportunity",
              action: "update-opportunity-value",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o valor da oportunidade <b>ID ${this.tempContract.opportunity_id}</b> ao gerar o contrato <b>ID ${this.tempContract.id}</b>.`,
            });
          });
    },
    onClickGenerateContract() {
      if (this.requestMiles().show && !this.verifyVoucherPayments()) {
        this.systemLoadings.generateContractLoading = false;
        this.$message.warning(
          "As taxas ainda não foram pagas. Verique os valores preenchidos."
        );
        return false;
      }

      if (
        this.checkIfAllMilesOrdersIsConcluded() == true &&
        this.milesCardOrdersIsConcluded == false
      ) {
        this.systemLoadings.generateContractLoading = false;
        this.$message.warning(
          "A emissão de milhas não foi concluída. Verifique os pedidos de milhas."
        );

        return false;
      }

      this.form.validateFields(["contract_documents"], (err) => {
        if (!err) {
          ///
          this.systemLoadings.generateContractLoading = true;
          this.protectCreditCardData();

          if (this.milesTaxesPayments().show) {
            this.tempContract.flight_taxes_total =
              this.milesTaxesPayments().totalTaxes;
          }

          if (this.tempContract.status == "canceled") {
            this.tempContract.status;
          } else {
            this.tempContract.status = "concluded";
          }

          this.tempContract.release_date = format(new Date(), "yyyy/MM/dd");
          this.tempContract["contract_done"] = true;
          this.tempContract.action = "generate-contract";

          if (this.milesTaxesPayments().show) {
            this.tempContract.flight_taxes_total =
              this.milesTaxesPayments().totalTaxes;
          }

          this.tempContract.user_id = this.$store.state.userData.id;
          this.tempContract.user_name =
            this.$store.state.userData.first_name +
            " " +
            this.$store.state.userData.last_name;
          this.tempContract.user_role = this.$store.state.userData.role;

          this.tempContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          document.title = `Salvando contrato...`;

          // Este método envia apenas os campos que foram alterados
          let contractDataToSave = this.compareContractObjects(
            this.contractFromDatabase,
            this.tempContract
          );

          contractDataToSave.id = this.tempContract.id;
          contractDataToSave.customer_id = this.tempContract.customer_id;
          contractDataToSave.opportunity_id = this.tempContract.opportunity_id;
          contractDataToSave.company_id = this.tempContract.company_id;
          contractDataToSave.company_branch_id =
            this.tempContract.company_branch_id;
          contractDataToSave.id_infotera = this.tempContract.id_infotera;
          contractDataToSave.package_type = this.tempContract.package_type;
          contractDataToSave.user = this.tempContract.user;
          contractDataToSave.company = this.tempContract.company;
          contractDataToSave.value = this.tempContract.value;
          contractDataToSave.value_to_pay = this.tempContract.value_to_pay;
          contractDataToSave.taxes_value = this.tempContract.taxes_value;
          contractDataToSave.profit_value = this.tempContract.profit_value;
          contractDataToSave.profit_percentage =
            this.tempContract.profit_percentage;
          contractDataToSave.contracted_services =
            this.tempContract.contracted_services;
          contractDataToSave.payment_methods =
            this.tempContract.payment_methods;
          contractDataToSave.marketing_types =
            this.tempContract.marketing_types;
          contractDataToSave.first_checkin = this.tempContract.first_checkin;
          contractDataToSave.last_checkout = this.tempContract.last_checkout;
          contractDataToSave.release_date = this.tempContract.release_date;
          contractDataToSave.status = this.tempContract.status;

          this.$http
            .post("/contract-v2/update", contractDataToSave)
            .then(({ data }) => {
              this.$message.success(data.message);

              document.title = this.theTitle;

              if (this.tempContract.status == "concluded") {
                if (
                  this.tempContract.create_opportunity == 1 &&
                  this.tempContract.create_opportunity == true
                ) {
                  this.createOpportunity();
                } else {
                  this.onGenerateContractCreateSale();
                  this.updateOpportunityValueOnFinishContract();
                }
              }
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        }
      });
    },
    submitContract(e) {
      e.preventDefault();
      if (e.submitter.id == "generate-contract") {
        //
      } else {
        if (this.$route.name == "new") {
          this.createContract();
        } else {
          this.updateContract(e.submitter.id);
        }
      }
    },
  },
};
